import React from "react";
import Button from "../Inputs/Button";
import Divider from "../Inputs/Divider";
import { useNavigate } from "react-router-dom";

interface WelcomeProps {
    Title: string,
    Message: string,
    ShowButton: boolean,
    ButtonText: string
}

const Welcome = (props: WelcomeProps) => {
    let navigate = useNavigate();
    
    return (
        <div className="home_header">
            <div className="overlay d-flex flex-column justify-content-center align-items-center">
                <h1>{props.Title}</h1>
                <Divider />
                <h5>{props.Message}</h5>
                {props.ShowButton && <Button success text={props.ButtonText} onClick={() => navigate("/contact")} />}
            </div>
        </div>
    );
}

export default Welcome;