import React from "react";
import { AboutUsItems, StringData, TheVision, TheVisionTitle, WhatWeOffer } from "../data/SiteData";
import AboutSummary from "../components/Modules/AboutSummary";
import AboutImageList from "../components/Modules/AboutImageList";
import Button from "../components/Inputs/Button";
import { useNavigate } from "react-router-dom";

const About = (props: any) => {
    let navigate = useNavigate();

    return (
        <>
        <div className="page_header"></div>
        {/*<AboutSummary Title={StringData.AboutTitle} Message={StringData.AboutMessage} ShowButton={false} ButtonText={StringData.AboutButton} Items={WhatWeOffer} />
        <AboutSummary Title={TheVisionTitle} Message={TheVision} ShowButton={false} ButtonText={StringData.AboutButton} Items={WhatWeOffer} />*/}
        <AboutImageList Title={StringData.AboutUsTitle} Items={[]} />
        <div className="centered mt-5">
            <Button success text="Get a Free Quote" onClick={() => navigate("/contact")} />
        </div>
        </>
    );
}

export default About;