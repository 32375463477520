import React from "react";
import Divider from "../components/Inputs/Divider";
import { StringData } from "../data/SiteData";

const Contact = (props: any) => {
    return (
        <>
        <div className="page_header"></div>
        <div className="about">
            <h3>{StringData.ContactUsTitle}</h3>
            <Divider />
            <div className="centered mt-5">
                <h3>{StringData.ContactUsHead}</h3>
                {StringData.ContactUsMessage}
            </div>
        </div>
        </>
    );
}

export default Contact;