import React from "react";
import Divider from "../Inputs/Divider";
import { JsxElement } from "typescript";

export interface AboutImageListItem {
    Title: string,
    Details: JSX.Element,
    Source: any
}

interface AboutImageListProps {
    Title: string,
    Items: AboutImageListItem[]
}

const AboutImageList = (props: AboutImageListProps) => {
    let itemCount = 0;
    var idx = -1;

    return (
        <div className="about">
            <h3>{props.Title}</h3>
            <Divider />
            <div className="d-flex flex-column">
                <div className="row">
                    {props.Items.map(item => {
                        itemCount++;
                        idx++;
                        if (itemCount % 2 === 0) {
                            return (
                                <>
                                <div className="col-lg-4 col-12 centered pt-5 d-lg-none bg-img" style={{backgroundImage:`url("${item.Source}")`}}>
                                    <h1>{item.Title}</h1>
                                </div>
                                <div className="col-lg-8 col-12 p-5 mb-lg-5">
                                    <div>
                                        {item.Details}<br /><br />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 centered pt-5 d-none d-lg-block bg-img" style={{backgroundImage:`url("${item.Source}")`}}>
                                    <h1>{item.Title}</h1>
                                </div>
                                </>
                            );
                        }
                        else {
                            return (
                                <>
                                <div className="col-lg-4 col-12 centered pt-5 bg-img" style={{backgroundImage:`url("${item.Source}")`}}>
                                    <h1>{item.Title}</h1>
                                </div>
                                <div className="col-lg-8 col-12 p-5 mb-lg-5">
                                    <div>
                                        {item.Details}<br /><br />
                                    </div>
                                </div>
                                </>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

export default AboutImageList;