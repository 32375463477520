import React from "react";
import Button from "../Inputs/Button";
import Divider from "../Inputs/Divider";
import { useNavigate } from "react-router-dom";

export interface AboutSummaryListItem {
    Title: string,
    Source: any
}

interface WelcomeSummaryProps {
    Title: string,
    Message: string,
    ShowButton: boolean,
    ButtonText: string,
    Items: AboutSummaryListItem[]
}

const AboutSummary = (props: WelcomeSummaryProps) => {
    let navigate = useNavigate();
    var idx = -1;
    
    return (
        <div className="home_wwo">
            <h3>{props.Title}</h3>
            <Divider />
            <div className="d-flex flex-column">
                <div className="row">
                    {props.Items.map(item => {
                        idx++;
                        return (
                            <div className="col-4 centered">
                                <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                <h5 className="centered">{item.Title}</h5>
                            </div>
                        );
                    })}
                </div>
                <div className="centered description">
                    {props.Message}<br /><br />
                </div>
                {props.ShowButton && <Button success text={props.ButtonText} onClick={() => navigate("/about")} />}
            </div>
        </div>
    );
}

export default AboutSummary;