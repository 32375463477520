import React from "react";
import Welcome from "../components/Modules/Welcome";
import AboutSummary from "../components/Modules/AboutSummary";
import { StringData, WhatWeOffer } from "../data/SiteData";

const Home = (props: any) => {
    return (
        <>
        <Welcome Title={StringData.WelcomeTitle} Message={StringData.WelcomeMessage} ShowButton={false} ButtonText={StringData.WelcomeButton} />
        <AboutSummary Title={StringData.AboutTitle} Message={StringData.AboutMessage} ShowButton ButtonText={StringData.AboutButton} Items={WhatWeOffer} />
        </>
    );
}

export default Home;